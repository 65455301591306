import Button from "components/common/Button";
import CategorySelector from "components/common/CategorySelector";
import Dropdown from "components/common/Dropdown";
import Layout from "components/layout/Layout";
import countries from "constants/operationCountries";
import SearchCoupons from "containers/oma/SearchCoupons";
import SearchPrograms from "containers/oma/SearchPrograms";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
} from "react";
import { twMerge } from "tailwind-merge";
import { ActionState, PageNumPaginatedActionState } from "types/data";
import PaidPlacementsTable from "./PaidPlacementsTable";
import {
  PLACEMENT_TYPE_COUPON_LISTINGS,
  PLACEMENT_TYPE_JUST_CAME_IN,
  PLACEMENT_TYPE_STORE_LISTINGS,
} from "constants/placementTypeIds";
import {
  PLACEMENT_DEST_CATEGORIES,
  PLACEMENT_DEST_EXTENSION,
  PLACEMENT_DEST_HOME_PAGE,
} from "constants/placementDestinations";
import PlacementDestination from "types/model/PlacementDestination";
import PlacementType from "types/model/PlacementType";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import { CLIENT_ID_DISCOUNT_CODES_COM, CLIENT_ID_DISCOUNTCODE_COM } from "constants/clientWebsitesIds";


type Props = {
  data: Array<Record<string, any>>;
  form: Record<string, any>;
  onSearchMerchantSelected: (result: Record<string, any>) => void;
  onSearchCouponSelected: (result: Record<string, any>) => void;
  handleRemoveSpotlight: (result: Record<string, any>) => void;
  handleAddNewItem: () => void;
  updatePaidPlacementData: ActionState;
  loading: boolean;
  handleUpdateItem: (
    oldRow: Record<string, any>,
    newForm: Record<string, any>
  ) => void;
  categorySelectionActive: boolean;
  addNewFormItem: (name: string, val: string) => void;
  setCategorySelectionActive: Dispatch<SetStateAction<boolean>>;
  checkPlacementPosAvailabilityData: ActionState;
  checkAvailability: (form: Record<string, any>) => void;
  filterBy: (val: string) => void;
  setAddForm: Dispatch<SetStateAction<Record<string, any>>>;
  addToPaidPlacementsData: ActionState;
  deletePaidPlacementData: ActionState;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  getPlacementTypesData: PageNumPaginatedActionState<PlacementType>;
  getPlacementDestinationsData: PageNumPaginatedActionState<PlacementDestination>;
};

export default function PaidPlacements({
  data,
  form,
  onSearchMerchantSelected,
  onSearchCouponSelected,
  handleAddNewItem,
  updatePaidPlacementData,
  loading,
  handleUpdateItem,
  handleRemoveSpotlight,
  addNewFormItem,
  checkPlacementPosAvailabilityData,
  deletePaidPlacementData,
  checkAvailability,
  filterBy,
  setAddForm,
  onChange,
  addToPaidPlacementsData,
  getPlacementTypesData,
  getPlacementDestinationsData,
}: Props) {

  const clientHasMultipleLocations = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID) == CLIENT_ID_DISCOUNT_CODES_COM;

  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">
              Paid Campaigns
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Manage the paid placement campaigns across all available Ad
              Placements.
            </p>

            <div className="flex items-center gap-4 mt-8">
              <Dropdown
                placeholder="Select Page"
                name="placement_destination"
                value={form.placement_destination}
                onChange={(e) => {
                  const {
                    target: { name, value },
                  } = e;
                  addNewFormItem(name, value);
                  onChange(e);

                  if (value === String(PLACEMENT_DEST_HOME_PAGE)) {
                    addNewFormItem("page_type", "homepage");

                    onChange({
                      target: { name: "category", value: "" },
                    } as ChangeEvent<HTMLInputElement>);
                  }
                }}
                options={getPlacementDestinationsData.data.results.map(
                  (el) => ({
                    label: el.name,
                    value: el.id,
                  })
                )}
              />

              {clientHasMultipleLocations && form.placement_destination &&
                form.placement_destination != PLACEMENT_DEST_CATEGORIES && (
                  <Dropdown
                    placeholder="Location"
                    name="location"
                    value={form.location}
                    onChange={(e) => {
                      const {
                        target: { value },
                      } = e;
                      setAddForm((f) => ({ ...f, location: value }));
                      onChange(e);
                    }}
                    options={countries.map((el) => ({
                      label: el,
                      value: el,
                    }))}
                  />
                )}

              {form.placement_destination ===
                String(PLACEMENT_DEST_CATEGORIES) && (
                <CategorySelector
                  placeholder="Select Category"
                  label=" "
                  value={form.category}
                  onResultClick={(result) => {
                    addNewFormItem("category", result.slug);
                    addNewFormItem("categoryId", result.id);
                    addNewFormItem("page_type", result.level);
                    addNewFormItem(
                      "placement_type",
                      String(PLACEMENT_TYPE_COUPON_LISTINGS)
                    );
                    onChange({
                      target: { name: "category", value: result.slug },
                    } as ChangeEvent<HTMLInputElement>);
                    onChange({
                      target: {
                        name: "placement_type",
                        value: String(PLACEMENT_TYPE_COUPON_LISTINGS),
                      },
                    } as ChangeEvent<HTMLInputElement>);
                  }}
                />
              )}

              <Dropdown
                placeholder="Select Placement Type"
                onChange={(e) => {
                  const {
                    target: { name, value },
                  } = e;
                  addNewFormItem(name, value);
                  onChange(e);
                }}
                name="placement_type"
                value={form.placement_type}
                options={getPlacementTypesData.data.results
                  .filter((el) => {
                    if (
                      form.placement_destination == PLACEMENT_DEST_EXTENSION
                    ) {
                      return [
                        PLACEMENT_TYPE_COUPON_LISTINGS,
                        PLACEMENT_TYPE_STORE_LISTINGS,
                      ].includes(Number(el.id));
                    } else {
                      return el;
                    }
                  })
                  .map((el: any) => ({
                    label: el.name,
                    value: el.id,
                  }))}
              />

              <div>
                <SearchPrograms
                  wrapperClassName="mx-0"
                  value={form.store?.name}
                  searchParams={clientHasMultipleLocations&&{ location: form.location }||{}}
                  onResultClicked={onSearchMerchantSelected}
                />
              </div>

              <SearchCoupons
                wrapperClassName="mx-0"
                value={form.coupon?.title}
                store={form.store}
                onResultClicked={onSearchCouponSelected}
              />

              {form.placement_type !== PLACEMENT_TYPE_JUST_CAME_IN && (
                <Dropdown
                  placeholder="Select Position"
                  onChange={({ target: { name, value } }) => {
                    addNewFormItem(name, value);
                  }}
                  loading={
                    form.position && checkPlacementPosAvailabilityData.loading
                  }
                  error={
                    form.position &&
                    checkPlacementPosAvailabilityData.data.exists
                      ? "Position already assigned"
                      : undefined
                  }
                  name="position"
                  value={form.position}
                  options={Array(form.placement_type==PLACEMENT_TYPE_COUPON_LISTINGS?50:10)
                    .fill("*")
                    .map((el, idx) => ({
                      label: String(idx + 1),
                      value: String(idx + 1),
                    }))}
                />
              )}

              <Button
                disabled={
                  (!form.store && !form.coupon) ||
                  addToPaidPlacementsData.loading
                }
                onClick={handleAddNewItem}
                loading={addToPaidPlacementsData.loading}
                text="Add"
                className={twMerge(
                  "",
                  form.store &&
                    form.coupon &&
                    "bg-blue-600 text-white hover:bg-blue-700"
                )}
              />
            </div>
          </div>
        </div>

        <PaidPlacementsTable
          checkAvailability={checkAvailability}
          checkPlacementPosAvailabilityData={checkPlacementPosAvailabilityData}
          data={data}
          form={form}
          filterBy={filterBy}
          handleRemoveSpotlight={handleRemoveSpotlight}
          handleUpdateItem={handleUpdateItem}
          loading={loading}
          onChange={onChange}
          updatePaidPlacementData={updatePaidPlacementData}
          deletePaidPlacementData={deletePaidPlacementData}
        />
      </div>
    </Layout>
  );
}
