import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import { GetStoreByNetworkPayload } from "client-apis/discountcodes";
import {
  CLIENT_ID_CODEPROMO_FR,
  CLIENT_ID_CODES_CO_UK,
  CLIENT_ID_CODES_DE,
  CLIENT_ID_COUPONCODES_NL,
  CLIENT_ID_COUPONS_CA,
  CLIENT_ID_DESCONTO_COM_BR,
  CLIENT_ID_DISCOUNT_CODES_COM,
  CLIENT_ID_DISCOUNTCODE_COM,
  CLIENT_ID_PROMO_CODES_COM,
  CLIENT_ID_SLEVOVYKOD_CZ,
} from "constants/clientWebsitesIds";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

type Params = {
  query?: string | GetStoreByNetworkPayload;
  id?: string;
  client_id?: string | null;
  useDis?: string;
};

export default createAsyncThunk(
  "clients/active-program",
  async ({ query, useDis, client_id }: Params, ThunkAPI) => {
    const migratedClients = [
      Number(CLIENT_ID_SLEVOVYKOD_CZ),
                Number(CLIENT_ID_COUPONCODES_NL),
                Number(CLIENT_ID_DISCOUNT_CODES_COM),
                Number(CLIENT_ID_DESCONTO_COM_BR),
                Number(CLIENT_ID_CODEPROMO_FR),
                Number(CLIENT_ID_CODES_DE),
                Number(CLIENT_ID_PROMO_CODES_COM),
                Number(CLIENT_ID_CODES_CO_UK),
                Number(CLIENT_ID_CODES_DE),
                 Number(CLIENT_ID_DISCOUNT_CODES_COM),
                      Number(CLIENT_ID_COUPONCODES_NL),
    ];
    let sourceClientId = client_id||Number(localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID));

    const clientId =
      useDis == "1"
        ? CLIENT_ID_DISCOUNT_CODES_COM
        : migratedClients.includes(
            Number(localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID))
          )
        ? CLIENT_ID_DISCOUNT_CODES_COM
        : String(localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID));

    try {
      const clientWebsite = new ClientAPI(clientId);

      if (typeof query == "string") {
        const response = await clientWebsite.getProgramDetails(
          query,
          String(sourceClientId) || clientId
        );


        return response.data;
      } else if (query) {
        const response = await clientWebsite.getProgramByNetworkId(query);

        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
