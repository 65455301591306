import Button from "components/common/Button";
import TextArea from "components/common/TextArea";
import Layout from "components/layout/Layout";
import { ChangeEventHandler } from "react";

type Props = {
  loading: boolean;
  saving: boolean;
  form: Record<string, any>;
  error: Record<string, any> | null;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
  onSubmit: () => void;
};

export default function OMACategoryContentLayout({
  form,
  onChange,
  error,
  onSubmit,
  saving,
  loading,
}: Props) {
  return (
    <Layout loading={loading} error={error?.message}>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex mb-4 items-center justify-between sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Category content {form.category?.name && `/${form.category?.name}`}
          </h1>
        </div>
        <TextArea
          label="Short form"
          onChange={onChange}
          value={form.short_form}
          name={"short_form"}
        >
          {form.short_form}
        </TextArea>
        <TextArea
          label="Long form"
          value={form.long_form}
          name={"long_form"}
          wrapperClassName="my-2"
          onChange={onChange}
        >
          {form.long_form}
        </TextArea>

        <Button
          loading={saving}
          type="button"
          text={"Save"}
          onClick={onSubmit}
          className="block rounded-md bg-primary-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600 my-8"
        />
      </div>
    </Layout>
  );
}
