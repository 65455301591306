// import { createAsyncThunk } from "@reduxjs/toolkit";
// import { BACKEND_BASE_URL } from "config/env";
// import { ITEMS_PER_PAGE } from "constants/misc";
// import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
// import axios from "helpers/axios";

import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import Thunk from "../app/Thunk";

// type Params = {
//   network_id?: string;
//   network_status?: string;
//   search_text?: string;
//   has_coupon_code?: string;
//   name?: string;
//   client?: null | string;
//   ignoreForClient?: boolean;
//   skipCache?: boolean;
//   rank?: string;
//   store_location?: string;
//   page?: number;
//   program_has_no_coupons?: string;
//   program_has_no_codes?: string;
//   program_has_search_campaign?: string;
// };

// export default createAsyncThunk(
//   "advertisers/links-grouped-by-program",
//   async (
//     {
//       network_id,
//       network_status,
//       search_text,
//       name,
//       has_coupon_code,
//       ignoreForClient,
//       skipCache,
//       store_location,
//       rank,
//       page,
//       program_has_no_coupons,
//       program_has_no_codes,
//       program_has_search_campaign,
//     }: Params,
//     ThunkAPI,
//   ) => {
//     const url = new URL(
//       `${BACKEND_BASE_URL}/admin/advertisers/links/group-by-active-programs`,
//     );
//     const client_id = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID);
//     if (client_id && !ignoreForClient) {
//       url.searchParams.set("client_id", client_id);
//     }

//     if (network_id) {
//       url.searchParams.set("network_id", network_id);
//     }

//     if (page) {
//       url.searchParams.set(
//         "offset",
//         String(page * (page === 1 ? 0 : ITEMS_PER_PAGE)),
//       );
//     }

//     if (store_location) {
//       url.searchParams.set("store_location", store_location);
//     }

//     if (program_has_search_campaign) {
//       url.searchParams.set(
//         "program_has_search_campaign",
//         program_has_search_campaign,
//       );
//     }
//     if (program_has_no_codes) {
//       url.searchParams.set("program_has_no_codes", program_has_no_codes);
//     }

//     if (program_has_no_coupons) {
//       url.searchParams.set("program_has_no_coupons", program_has_no_coupons);
//     }
//     if (rank) {
//       url.searchParams.set("rank", rank);
//     }

//     if (has_coupon_code) {
//       url.searchParams.set("has_coupon_code", has_coupon_code);
//     }
//     if (network_status) {
//       url.searchParams.set("network_status", network_status);
//     }
//     if (search_text) {
//       url.searchParams.set("search_text", search_text);
//     }

//     if (name) {
//       url.searchParams.set("name", name);
//     }

//     if (client_id) {
//       url.searchParams.set("ignored_client_id", client_id);
//     }

//     try {
//       const response = await axios.get(
//         url.pathname
//           .replace("/api", "")
//           .replace("/production", "")
//           .replace("/dev", "") + url.search,

//         {
//           headers: skipCache
//             ? {
//                 "cache-control": "no-cache",
//               }
//             : {},
//         },
//       );

//       return response.data;
//     } catch (error: any) {
//       return ThunkAPI.rejectWithValue(
//         error.response?.data || {
//           message: "Could not fetch",
//         },
//       );
//     }
//   },
// );



export default Thunk("advertisers/grouped-links", async (filters) => {
  const url = new URL(`${DISCOUNT_CODES_API}/integrations/group-api-coupons-programs/`);

  Object.entries(filters).forEach(([k, v]) => {
    if(v){

      url.searchParams.set(k, String(v));
    }
  });

    url.searchParams.set('store__client', filters.client||localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID))

  return await instance.get(url.href);
});
