import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import IgnoreLink from "redux/thunks/links/IgnoreLink";
import getLinks from "redux/thunks/links/getLinks";
import { PageNumPaginatedActionState } from "types/data";
import { APICoupon } from "types/model/APICoupon";

export type ActionStateType = {
  data: PageNumPaginatedActionState<APICoupon>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: PageNumPaginatedActionState<APICoupon> = {
  data: {  
    results: [],
    count: 0,
    page: 1,
    next: null,
    previous: null,},
  loading: false,
  error: null,
};

const getLinksSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    resetLinks: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLinks.pending, (state, action) => {
        return {
          ...state,
          error: null,
          data: action.meta.arg.skipCache ? state.data : initialState.data,
          loading: true,
        };
      })
      .addCase(getLinks.fulfilled, (state, { payload }: PayloadAction<any>) => {
          return {
            ...state,
            data: payload,
            loading: false,
          };
      })
      .addCase(
        IgnoreLink.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
            return {
              ...state,
              data: {
                ...state.data,
                results: state.data.results.filter(
                  (it) =>
                    !payload.ids
                      .map((it: number) => String(it))
                      .includes(String(it.id)),
                ),
              },
              loading: false,
            };
        },
      )

      .addCase(getLinks.rejected, (state, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: false,
        error: payload,
      }));
  },
});
export const { resetLinks } = getLinksSlice.actions;
export default getLinksSlice.reducer;
