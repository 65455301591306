import { DISCOUNT_CODES_API } from "constants/api-clients";
import Thunk from "../app/Thunk";
import { instance } from "client-apis/discountcodes";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";


export default Thunk("advertisers/links", async (filters) => {
  const url = new URL(`${DISCOUNT_CODES_API}/integrations/api-coupons/`);

  Object.entries(filters).forEach(([k, v]) => {
    if(v){

      url.searchParams.set(k, String(v));
    }
  });

    url.searchParams.set('store__client', filters.client||localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID))

  return await instance.get(url.href);
});
