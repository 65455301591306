import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getLink from "redux/thunks/links/getLink";
import { APICoupon } from "types/model/APICoupon";

export type ActionStateType = {
  data: APICoupon|null;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: ActionStateType = {
  data: null,
  loading: false,
  error: null,
};

const getLinkSlice = createSlice({
  name: "link",
  initialState,
  reducers: {
    resetLink: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLink.pending, (state) => {
        return {
          ...state,
          error: null,

          loading: true,
        };
      })
      .addCase(getLink.fulfilled, (state, { payload }: PayloadAction<any>) => {
          return {
            ...state,
            data: payload,
            loading: false,
          };
      })

      .addCase(getLink.rejected, (state, { payload }: PayloadAction<any>) => ({
        ...state,
        loading: false,
        error: payload,
      }));
  },
});
export const { resetLink } = getLinkSlice.actions;
export default getLinkSlice.reducer;
