import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { toast } from "react-hot-toast";
import { clearStoreSearchResults } from "redux/slices/clients/searchActivePrograms";
import { clearSearchCouponResults } from "redux/slices/clients/coupons/searchActiveCoupons";
import OMACampaignsHomeCoupons from "layout/oma/campaigns/CampaignsHomeCouponListings";
import { setHomeCoupons } from "redux/slices/clients/placements/campaigns/getHomeCoupons";
import getPaidPlacements from "redux/thunks/app/placements/campaigns/getPaidPlacements";
import { useSearchParams } from "react-router-dom";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";
import dayjs from "dayjs";
import dateformat from "dateformat";
import addToPaidPlacements from "redux/thunks/app/placements/campaigns/addToPaidPlacements";
import deletePaidPlacement from "redux/thunks/app/placements/campaigns/deletePaidPlacement";
import { PLACEMENT_TYPE_COUPON_LISTINGS } from "constants/placementTypeIds";
import { PLACEMENT_DEST_HOME_PAGE } from "constants/placementDestinations";

const HomeCoupons = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();

  const [addForm, setAddForm] = useState<Record<string, any>>({});

  const {
    getPaidPlacements: getHomeCouponsData,
    addToPaidPlacements: { loading: saving },
  } = useAppSelector(
    ({ getClients, getPaidPlacements, addToPaidPlacements }) => ({
      getClients,
      getPaidPlacements,
      addToPaidPlacements,
    })
  );

  const [sortedItems, setSortedItems] = useState<Record<string, any>[]>([]);

  const getPaidPlacementItems = () => {
    dispatch(
      getPaidPlacements({
        location: params.get("location") || "",
        placement_destination: params.get("placement_destination") || "",
        placement_type: params.get("placement_type") || "",
        status: params.get("status") || "",
        category: params.get("category") || "",
        client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
      })
    );
  };

  useEffect(() => {
    getPaidPlacementItems();
  }, []);

  const handleAddNewItem = () => {
    dispatch(
      addToPaidPlacements({
        from_date: dateformat(dayjs(new Date()).toDate(), "yyyy-mm-dd"),
        to_date: null,
        coupon: addForm.coupon,
        store: addForm.store.id,
        position: addForm.position || null,
        placement_destination: PLACEMENT_DEST_HOME_PAGE,
        category: addForm.categoryId || null,
        placement_type: PLACEMENT_TYPE_COUPON_LISTINGS,
        slogan: "",
        location: addForm.store?.location || null,
        client: localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID),
        
      })
    ).then((res) => {
      if (res.meta.requestStatus == "fulfilled") {
        getPaidPlacementItems();
      }
    });

    setAddForm({});
  };

  const handleRemoveSpotlight = (result: Record<string, any>) => {
    dispatch(deletePaidPlacement(result.id)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        toast.success("Placement deleted");
        getPaidPlacementItems();
      }
    });
  };

  useEffect(() => {
    setSortedItems(getHomeCouponsData.data);
  }, [getHomeCouponsData.data]);

  const handleUpdateItem = (
    row: Record<string, any>,
    updatedItem: Record<string, any>
  ) => {
    const updatedItems = sortedItems.map((item) =>
      item.id === row.id ? updatedItem : item
    );

    dispatch(setHomeCoupons(updatedItems));
    setAddForm({});
  };

  const onSearchMerchantSelected = (merchant: Record<string, any>) => {
    setAddForm((values) => ({ ...values, store: merchant }));
    dispatch(clearStoreSearchResults());
  };

  const onSearchCouponSelected = (coupon: Record<string, any>) => {
    setAddForm((values) => ({ ...values, coupon }));
    dispatch(clearSearchCouponResults());
  };

  useEffect(() => {
    if (sortedItems) {
      dispatch(setHomeCoupons(sortedItems));
    }
  }, [sortedItems]);

  return (
    <OMACampaignsHomeCoupons
      data={sortedItems}
      onSearchMerchantSelected={onSearchMerchantSelected}
      form={addForm}
      saving={saving}
      loading={getHomeCouponsData.loading}
      handleAddNewItem={handleAddNewItem}
      onSearchCouponSelected={onSearchCouponSelected}
      handleUpdateItem={handleUpdateItem}
      handleRemoveSpotlight={handleRemoveSpotlight}
    />
  );
};

export default HomeCoupons;
