import { createAsyncThunk } from "@reduxjs/toolkit";
import ClientAPI from "client-apis";
import {
  CLIENT_ID_CODEPROMO_FR,
  CLIENT_ID_CODES_CO_UK,
  CLIENT_ID_CODES_DE,
  CLIENT_ID_COUPONCODES_NL,
  CLIENT_ID_DESCONTO_COM_BR,
  CLIENT_ID_DISCOUNT_CODES_COM,
  CLIENT_ID_PROMO_CODES_COM,
  CLIENT_ID_SLEVOVYKOD_CZ,
} from "constants/clientWebsitesIds";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

type Params = {
  id?: string;
  client_id: string;
};

export default createAsyncThunk(
  "clients/active-program",
  async ({ client_id, id }: Params, ThunkAPI) => {
    const migratedClients = [
      Number(CLIENT_ID_SLEVOVYKOD_CZ),
           Number(CLIENT_ID_COUPONCODES_NL),
           Number(CLIENT_ID_DISCOUNT_CODES_COM),
           Number(CLIENT_ID_DESCONTO_COM_BR),
           Number(CLIENT_ID_CODEPROMO_FR),
           Number(CLIENT_ID_CODES_DE),
           Number(CLIENT_ID_PROMO_CODES_COM),
           Number(CLIENT_ID_CODES_CO_UK),
           Number(CLIENT_ID_CODES_DE),
            Number(CLIENT_ID_DISCOUNT_CODES_COM),
                 Number(CLIENT_ID_COUPONCODES_NL),
    ];
    const clientId = migratedClients.includes(
      Number(client_id) ||
        Number(localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID))
    )
      ? CLIENT_ID_DISCOUNT_CODES_COM
      : CLIENT_ID_PROMO_CODES_COM;
    try {
      const clientWebsite = new ClientAPI(clientId);

      if (id) {
        const response = await clientWebsite.getProgramDetails(id, clientId);

        return response.data;
      }
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        }
      );
    }
  }
);
