import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import getLinksByProgram from "redux/thunks/links/getLinksByProgram";
import { PageNumPaginatedActionState, PaginatedData } from "types/data";
import { ProgramAPICouponGroup } from "types/model/APICoupon";

export type ActionStateType = {
 ata: PageNumPaginatedActionState<ProgramAPICouponGroup>;
  loading: boolean;
  error: Record<string, any> | null;
};

const initialState: PageNumPaginatedActionState<ProgramAPICouponGroup> = {
  data: {
    results: [],
    count: 0,
    page: 1,
    next: null,
    previous: null,
  },
  loading: false,
  error: null,
};

const getLinksByProgramSlice = createSlice({
  name: "getLinksByProgram",
  initialState,
  reducers: {
    resetGetLinksByProgram: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLinksByProgram.pending, (state, action) => {
        return {
          ...state,
          error: null,
          data: action.meta.arg.skipCache ? state.data : initialState.data,
          loading: true,
        };
      })
      .addCase(
        getLinksByProgram.fulfilled,
        (state, { payload }: PayloadAction<any>) => {
            return {
              ...state,
              data: payload,
              loading: false,
            };
        },
      )

      .addCase(
        getLinksByProgram.rejected,
        (state, { payload }: PayloadAction<any>) => ({
          ...state,
          loading: false,
          error: payload,
        }),
      );
  },
});
export const { resetGetLinksByProgram } = getLinksByProgramSlice.actions;
export default getLinksByProgramSlice.reducer;
