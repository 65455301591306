import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import getLinks from "redux/thunks/links/getLinks";
import PendingProgramCouponsComponent from "layout/oma/PendingProgramCoupons";
import toast from "react-hot-toast";
import IgnoreLink from "redux/thunks/links/IgnoreLink";
import { APICoupon } from "types/model/APICoupon";

type Props = {
  page?: string;
  network?: string;
  program_id?: string;
  ordering?: string;
  links?: APICoupon[]
};

const PendingProgramCoupons = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [selectedItems, setSelectedItems] = useState<Record<string, any>[]>([]);

  const {
    page = props.page,
    network = props.network,
    program_id = props.program_id,
    ordering = "coupon_code",
  } = Object.fromEntries([...searchParams]);

  const {
    getAllLinks: getAllLinksState,
    ignoreLinks: { loading: ignoreLoading },
    getProgram: getProgramData,
  } = useAppSelector(({ getAllLinks, ignoreLinks, getProgram }) => ({
    getAllLinks,
    ignoreLinks,
    getProgram,
  }));

  const onActivatePressed = (link: Record<string, any>) => {
    navigate(
      `/oma/program?&program_id=${link?.store?.id}&slug=${link?.store?.slug}&client_id=${link.store.client}&tab=create-coupon`,
      {
        state: link,
      },
    );
  };

  const getNewPage = (page: number) => {
    const url = new URL(window.location.href);
    url.searchParams.set("page", String(page));
    navigate(url.search);
  };

  const loadNetworkMidLinks = () => {
    dispatch(
      getLinks({
        page: 1,
        program_id: program_id,
        network_id: network,
        ordering,
      }),
    );
  };

  const loadProgramIdLinks = () => {
    dispatch(
      getLinks({ page: Number(page) || 1, program_id: program_id, ordering }),
    );
  };

  useEffect(() => {
    if (program_id && !(program_id && network)) {
      loadProgramIdLinks();
    }
  }, [program_id]);

  useEffect(() => {
    if (program_id && network) {
      loadNetworkMidLinks();
    }
  }, []);

  useEffect(() => {
    if (!program_id && !network && getProgramData.data.primary_network) {
      dispatch(
        getLinks({
          page: 1,
          mid: getProgramData.data.primary_network.network_mid,
          network_id: getProgramData.data.primary_network.name,
          ordering,
        }),
      );
    }
  }, []);

  const handleIgnoreLinks = (link?: Record<string, any>) => {
    if (link?.id) {
      setSelectedItems([link]);
    }
    dispatch(
      IgnoreLink({
        data: {
          ids: link?.id ? [link.id] : selectedItems.map((it) => String(it.id)),
          action: "listing",
        },
      }),
    )
      .then((res) => {
        setSelectedItems([]);
        if (res.meta.requestStatus === "fulfilled") {
          if (program_id) {
            getLinks({
              page: Number(page) || 1,
              program_id: program_id,
              ordering,
              skipCache: true,
            });
          }
          dispatch(
            getLinks({
              page: Number(page) || 1,
              program_id: program_id,
              network_id: network,
              ordering,
              skipCache: true,
            }),
          );
          toast.success("Link(s) ignored");
        }
      })
      .catch((err) => {
        //
      });
  };

  return (
    <PendingProgramCouponsComponent
      onPageChange={getNewPage}
      currentPage={Number(page) || 1}
      onActivatePressed={onActivatePressed}
      getAllLinksState={getAllLinksState}
      handleIgnoreLinks={handleIgnoreLinks}
      ignoreLoading={ignoreLoading}
      links={props.links}
    />
  );
};

export default PendingProgramCoupons;
