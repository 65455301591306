import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";

export default createAsyncThunk(
  "advertisers/link",
  async (linkId: string, ThunkAPI) => {
    try {
      const response = await instance.get(
     `${DISCOUNT_CODES_API}/integrations/api-coupons/${linkId}`
      );

      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data || {
          message: "Could not fetch",
        },
      );
    }
  },
);
