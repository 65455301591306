import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import OMAProgramComponent from "layout/oma/OMAProgram";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import getProgram from "redux/thunks/app/api-clients/getProgram";
import getLink from "redux/thunks/links/getLink";
import { resetLink } from "redux/slices/links/getLink";
import { setProgramDetails } from "redux/slices/clients/getProgram";

const OMAProgram = () => {
  const { state } = useLocation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const slug = params.get("slug");

  const { getProgram: getProgramData } = useAppSelector((state) => state);

  const link = state;

  const {
    createUpdateCoupon: createUpdateCouponData,
    getLink: { data: getLinkData },
  } = useAppSelector(({ createUpdateCoupon, getLink }) => ({
    createUpdateCoupon,
    getLink,
  }));

  useEffect(() => {
    if (params.has("link") && params.get("activate") !== "0") {
      dispatch(getLink(params.get("link") || ""));
    } else {
      dispatch(resetLink());
    }
  }, [params]);

  useEffect(() => {
    if (
      !slug &&
      params.has("mid") &&
      params.has("network") &&
      !params.has("id")
    ) {
      const mid =
        params.get("mid") !== "null"
          ? params.get("mid")
          : params.get("program_id");
      dispatch(
        getProgram({
          query: {
            mid,
            network: params.get("network") || "",
          },
        })
      );
    }
  }, [params]);

  useEffect(() => {
    if (slug) {
      dispatch(
        getProgram({
          query: slug || "",
        })
      );
    }
  }, [slug]);

  useEffect(() => {
    return () => {
      dispatch(resetLink());
    };
  }, []);

  const setSelectedProgram = (item: Record<string, any>) => {
    const url = new URL(window.location.href);
    url.searchParams.set("id", item.id);
    dispatch(setProgramDetails(item));
    navigate(url.search);
  };

  return (
    <OMAProgramComponent
      link={link || getLinkData}
      linkId={params.get("link")}
      getProgramData={getProgramData}
      createUpdateCouponData={createUpdateCouponData}
      setSelectedProgram={setSelectedProgram}
      onAddCouponClicked={() => {
        const url = new URL(window.location.href);
        url.searchParams.set("tab", "create-coupon");
        url.searchParams.set("activate", "0");

        navigate(url.search, {
          state,
        });

        dispatch(resetLink());
      }}
    />
  );
};

export default OMAProgram;
