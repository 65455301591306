import { createAsyncThunk } from "@reduxjs/toolkit";
import { instance } from "client-apis/discountcodes";
import { DISCOUNT_CODES_API } from "constants/api-clients";
import { LOCAL_KEY_SELECTED_CLIENT_ID } from "constants/storageKeys";

type Params = {
  data: {
    ids: string[];
    action: "listing";
  };
};

export default createAsyncThunk(
  "admin/advertisers/links/ignore-links/",
  async ({ data }: Params, ThunkAPI) => {
    const client_id = localStorage.getItem(LOCAL_KEY_SELECTED_CLIENT_ID);

    const payload = {
      ...data,
      client_id: client_id,
      ignore_for_action: data.action,
    };
    try {
      const response = await instance.post(
        `${DISCOUNT_CODES_API}/integrations/ignore-links/`,
        payload,
      );
      return response.data;
    } catch (error: any) {
      return ThunkAPI.rejectWithValue(
        error.response?.data ||
          error || {
            message: "Could not fetch",
          },
      );
    }
  },
);
