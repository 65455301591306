import DataTable from "components/common/layout/DataTable";
import Layout from "components/layout/Layout";
import LinkRow from "components/links/LinkRow";
import LINK_COLUMNS from "constants/tables/headers/oma/links";
import {  PageNumPaginatedActionState} from "types/data";
import Message from "components/common/Message";
import { APICoupon } from "types/model/APICoupon";

type Props = {
  getAllLinksState: PageNumPaginatedActionState<APICoupon>;
  onActivatePressed: (arg: Record<string, any>) => void;
  handleIgnoreLinks: (link?: Record<string, any>) => void;
  ignoreLoading: boolean;
  onPageChange: (page: number) => void;
  currentPage: number;
  links?: APICoupon[]
};

const PendingProgramCouponsComponent = ({
  onActivatePressed,
  getAllLinksState: { loading, data, error },
  onPageChange,
  currentPage,
  ignoreLoading,
  handleIgnoreLinks,
  links
}: Props) => {
  if (error?.detail) {
    return (
      <Layout>
        <Message title="Error!" info description={error?.detail} />
      </Layout>
    );
  }

  return (
    <div className="my-4">
      <DataTable
        totalCount={0}
        onPageChange={onPageChange}
        columns={LINK_COLUMNS.filter(
          (it) =>
            it.key !== "checkbox" &&
            it.key !== "created_at" &&
            it.key !== "network_advertiser.name" &&
            it.key !== "network"
        ).map((el) => ({ ...el, isSortable: false }))}
        rows={(links?.length||0)>0?links:data.results}
        currentPage={currentPage}
        rowRenderer={(row: any) => (
          <LinkRow
            item={row}
            onActivatePressed={onActivatePressed}
            handleIgnoreLinks={handleIgnoreLinks}
            ignoreLoading={ignoreLoading}
            skippableFields={[
              "programName",
              "checkbox",
              "network",
              "created_at",
            ]}
          />
        )}
        loading={loading}
      />
    </div>
  );
};

export default PendingProgramCouponsComponent;
