import PaidPlacementRow from "components/campaigns/PaidPlacementRow";
import Checkbox from "components/common/Checkbox";
import Message from "components/common/Message";
import Spinner from "components/common/layout/Spinner";
import { PLACEMENT_DEST_CATEGORIES } from "constants/placementDestinations";
import { ChangeEventHandler } from "react";
import { ActionState } from "types/data";

type Props = {
  loading: boolean;
  data: Array<Record<string, any>>;
  filterBy: (val: string) => void;
  updatePaidPlacementData: ActionState;
  handleUpdateItem: (
    oldRow: Record<string, any>,
    newForm: Record<string, any>
  ) => void;
  checkPlacementPosAvailabilityData: ActionState;
  checkAvailability: (form: Record<string, any>) => void;
  handleRemoveSpotlight: (result: Record<string, any>) => void;
  form: Record<string, any>;
  deletePaidPlacementData: ActionState;
  onChange : ChangeEventHandler<HTMLInputElement>
};

const PaidPlacementsTable = ({
  loading,
  data,
  filterBy,
  checkAvailability,
  checkPlacementPosAvailabilityData,
  handleRemoveSpotlight,
  handleUpdateItem,
  updatePaidPlacementData,
  form,
  deletePaidPlacementData,
  onChange
}: Props) => {
  return (
    <div className="mt-8 flow-root">
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="w-full sm:pl-6 lg:pl-8 flex items-center justify-between mt-4">
              <div className="flex gap-6 flex-col w-full rounded-md shadow-sm md:w-auto md:flex-row">
                {["active", "scheduled", "all", "past"].map((el) => (
                  <button
                    key={el}
                    className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-b-lg rounded-bl-none rounded-r-lg"
                    onClick={() => {
                      filterBy(el);
                    }}
                  >
                    {el}
                  </button>
                ))}
                <Checkbox name="paid" label="Paid" onChange={(e)=>{
                    onChange({
                      ...e,
                      target:{
                        ...e.target,
                        name:"paid",
                        value: e.target.checked?'1':'0'
                      }
                    });
                }} checked={!!Number(form.paid)}/>
              </div>
            </div>
            {data.length > 0 ? (
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Creation Date
                        </th>

                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Page
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Page Type
                        </th>

                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Placement Type
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Position
                        </th>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Affiliate Network
                        </th>

                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Program Name
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Coupon ID
                        </th>

                        {form.placement_description !==
                          PLACEMENT_DEST_CATEGORIES && (
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Image
                          </th>
                        )}
                        {form.placement_description !==
                          PLACEMENT_DEST_CATEGORIES && (
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Slogan
                          </th>
                        )}
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Coupon Title
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Run Dates
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Paid
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Manage</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {data.map((el) => (
                        <PaidPlacementRow
                          handleUpdateItem={handleUpdateItem}
                          row={el}
                          key={el.id}
                          form={form}
                          checkAvailability={checkAvailability}
                          checkPlacementPosAvailabilityData={
                            checkPlacementPosAvailabilityData
                          }
                          updatePaidPlacementData={updatePaidPlacementData}
                          deletePaidPlacementData={deletePaidPlacementData}
                          handleRemoveSpotlight={handleRemoveSpotlight}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div>
                <Message icon={false} success description="No items" />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default PaidPlacementsTable;
