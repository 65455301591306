import React, { ChangeEventHandler, useEffect, useState } from "react";
import dateFormat from "dateformat";
import Modal from "components/common/Modal";
import Input from "components/common/Input";
import Dropdown from "components/common/Dropdown";
import { ActionState } from "types/data";
import { truncate } from "lodash";
import {
  PLACEMENT_TYPE_COUPON_LISTINGS,
  PLACEMENT_TYPE_HERO_COUPON,
  PLACEMENT_TYPE_JUST_CAME_IN,
} from "constants/placementTypeIds";
import {
  PLACEMENT_DEST_CATEGORIES,
  PLACEMENT_DEST_EXTENSION,
} from "constants/placementDestinations";
import { CheckIcon } from "@heroicons/react/20/solid";
import Checkbox from "components/common/Checkbox";

type Props = {
  row: Record<string, any>;
  handleRemoveSpotlight: (it: Record<string, any>) => void;
  checkPlacementPosAvailabilityData: ActionState;
  handleUpdateItem: (
    row: Record<string, any>,
    form: Record<string, any>
  ) => void;
  form: Record<string, any>;
  checkAvailability: (form: Record<string, any>) => void;
  deletePaidPlacementData: ActionState;
  updatePaidPlacementData: ActionState;
};

const PaidPlacementRow = ({
  row,
  handleRemoveSpotlight,
  handleUpdateItem,
  checkPlacementPosAvailabilityData,
  checkAvailability,
  deletePaidPlacementData,
  form: outerForm,
  updatePaidPlacementData,
}: Props) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [form, setForm] = useState<Record<string, any>>(row);
  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    target: { name, value, checked },
  }) => {
    if (name == "paid") {
      setForm((f) => ({ ...f, [name]: checked }));
    } else {
      setForm((f) => ({ ...f, [name]: value }));
    }
  };

  useEffect(() => {
    if (form.placement_destination && form.position && editModalOpen) {
      checkAvailability({
        category: form.category,
        placement_destination: form.placement_destination,
        position: form.position,
        placement_type: form.placement_type,
        location: form.location,
      });
    }
  }, [form.position]);

  useEffect(() => {
    if (updatePaidPlacementData.data.id) {
      setEditModalOpen(false);
    }
  }, [updatePaidPlacementData.data]);

  return (
    <React.Fragment>
      <Modal
        wrapperClassName="w-72 h-52 min-h-52"
        onConfirm={() => {
          handleRemoveSpotlight(row);
        }}
        open={deleteModalOpen}
        loading={deletePaidPlacementData.loading}
        setOpen={setDeleteModalOpen}
        variant="sm"
        doneText="Proceed"
        doneBtnClass="bg-red-400 hover:bg-red-700"
      >
        <p>Are you sure you want to remove this placement?</p>
      </Modal>

      <Modal
        onConfirm={() => {
          handleUpdateItem(row, form);
        }}
        open={editModalOpen}
        setOpen={setEditModalOpen}
        doneText="Save Changes"
        loading={updatePaidPlacementData.loading}
      >
        <div className="grid gap-4 sm:grid-cols-2">
          {form.placement_type?.id === PLACEMENT_TYPE_HERO_COUPON && (
            <div>
              <div className="sm:col-span-2">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  htmlFor="file_input"
                >
                  Upload avatar
                </label>
                <div className="items-center w-full sm:flex">
                  {(form.tempImage || form.image_url) && (
                    <img
                      className="mb-4 w-80 h-60 rounded-md sm:mr-4 sm:mb-0 object-cover"
                      src={form.tempImage || form.image_url}
                      alt="Helene avatar"
                    />
                  )}
                  <div className="w-full">
                    <input
                      className="w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      aria-describedby="file_input_help"
                      id="file_input"
                      type="file"
                      name="file"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={(e) => {
                        setForm((pre) => ({
                          ...pre,
                          file: e.target.files?.[0] as any,
                          tempImage: URL.createObjectURL(
                            e.target.files?.[0] as any
                          ),
                        }));
                      }}
                    />
                    <p
                      className="mt-1 mb-3 text-xs font-normal text-gray-500 dark:text-gray-300"
                      id="file_input_help"
                    >
                      PNG, JPG (MAX. 800x400px).
                    </p>
                  </div>
                </div>
              </div>
              <Input
                name="slogan"
                onChange={onChange}
                id="slogan"
                label="slogan"
                value={form.slogan}
                required
              />
            </div>
          )}
          {form.placement_type !== PLACEMENT_TYPE_JUST_CAME_IN && (
            <Dropdown
              placeholder="Position"
              label="Select Position"
              onChange={onChange}
              value={form.position}
              name="position"
              loading={checkPlacementPosAvailabilityData.loading}
              error={
                checkPlacementPosAvailabilityData.data.exists
                  ? "Position already assigned"
                  : undefined
              }
              options={Array(form.placement_type?.id==PLACEMENT_TYPE_COUPON_LISTINGS?50:10)
                .fill("*")
                .map((el, idx) => ({
                  label: String(idx + 1),
                  value: String(idx + 1),
                }))}
            />
          )}

          {form.placement_type?.id === PLACEMENT_TYPE_JUST_CAME_IN && (
            <Input
              name="slogan"
              onChange={onChange}
              id="slogan"
              label="slogan"
              value={form.slogan}
              required
            />
          )}

          <Input
            type="date"
            onChange={onChange}
            label="From date"
            value={form.from_date}
            name={"from_date"}
          />
          <Input
            type="date"
            name="to_date"
            label="To date"
            onChange={onChange}
            value={form.to_date}
          />

          <Checkbox
            label="Is Paid"
            name="paid"
            checked={form.paid}
            onChange={
              onChange
            }
          />
        </div>
      </Modal>
      <tr key={row.id}>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {dateFormat(row.created_at, "yyyy/dd/mm")}{" "}
        </td>

        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {row.placement_destination?.name}{" "}
          {row.placement_destination === PLACEMENT_DEST_CATEGORIES &&
            `/${row.category}`}
        </td>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {row.placement_destination?.id == PLACEMENT_DEST_EXTENSION
            ? "N/A"
            : row.page_type}
        </td>

        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {row.placement_type?.name || row.placement_type}
        </td>

        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
          {row.position}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {row.store.primary_network?.name || "_"}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {row.store?.name || "_"}
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {row.store?.top_coupon?.id || ""}
        </td>
        {outerForm.placement_destination !== PLACEMENT_DEST_CATEGORIES && (
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {row.placement_type === PLACEMENT_TYPE_COUPON_LISTINGS ? (
              "N/A"
            ) : (
              <img
                alt=""
                src={row.tempImage || row.image_url}
                width={200}
                height={100}
              />
            )}
          </td>
        )}
        {outerForm.placement_type !== PLACEMENT_DEST_CATEGORIES && (
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {row.placement_type === PLACEMENT_TYPE_COUPON_LISTINGS
              ? "N/A"
              : row.slogan}
          </td>
        )}
        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
          {truncate(row.store?.top_coupon?.title || "")}
        </td>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {row.from_date ? dateFormat(row.from_date, "dd/mm/yyyy") : "Not set"}{" "}
          - {row.to_date ? dateFormat(row.to_date, "dd/mm/yyyy") : "Not set"}
        </th>

        <th
          scope="col"
          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
        >
          {row.paid ? <CheckIcon height={24} color="green" /> : null}
        </th>

        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
          <button
            onClick={() => {
              setEditModalOpen(true);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            Manage<span className="sr-only"></span>
          </button>

          <button
            className="mx-4"
            onClick={() => {
              setDeleteModalOpen(true);
            }}
          >
            X
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default PaidPlacementRow;
